html {
    overflow: visible;
    position: relative;

    /* prevent iOS from showing callouts like when long pressing an image */
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;

    /* Prevent font scaling in landscape while allowing user zoom */
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;

    /* make things more "app" like: disable text selection */
    // -webkit-user-select: none;

    
    --gutter: 2rem;
    --gutter-negative: calc(-1 * var(--gutter));
    --view-gutter: var(--gutter); // DEPRECATED: use --gutter

    --safe-bottom: env(safe-area-inset-bottom);
    --safe-top: env(safe-area-inset-top);
    --safe-left: env(safe-area-inset-left);
    --safe-right: env(safe-area-inset-right);

    --gutter-safe-bottom: calc(var(--gutter) + var(--safe-bottom));
    --gutter-safe-top: calc(var(--gutter) + var(--safe-top));
    --gutter-safe-left: calc(var(--gutter) + var(--safe-left));
    --gutter-safe-right: calc(var(--gutter) + var(--safe-right));
    --gutter-safe: var(--gutter-safe-top) var(--gutter-safe-right) var(--gutter-safe-bottom) var(--gutter-safe-left);

    --font-system: -apple-system, BlinkMacSystemFont, "Segoe UI",
               Roboto, Oxygen-Sans, Ubuntu, Cantarell,
               "Helvetica Neue", sans-serif;

    /* https://spencermortensen.com/articles/typographic-scale/ */
    --font-size-xs: .65em;
    --font-size-sm: .8409em;
    --font-size-rg: 1em;
    --font-size-md: 1.1892em;
    --font-size-lg: 1.4142em;
    --font-size-xl: 1.6818em;
    --font-size-xxl: 2em;
}

html.ios.installed {
    // top: env(safe-area-inset-top);
    top: calc(env(safe-area-inset-top) / 1.25);
    height: calc( 100% + (env(safe-area-inset-top) / 4));
}

html.installed.ios,
html.installed.android {
    /* make things more "app" like: disable text selection */
    -webkit-user-select: none;
}

html, body {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

/*
    Only require one side to be large. Android will shrink the viewport
    when the keyboard opens
*/
@media 
(min-width:600px) and (min-height:400px) and (max-height: 900px),
(min-width:400px) and (min-height:600px) and (max-width: 900px)
{
    html.touch,
    html.touch body {
        // little larger on tablets
        font-size: 18px !important;
    }
}

// slightly larger menu on small screens
@media
screen and (orientation:landscape) and (max-width:999px) and (max-height:800px),
screen and (orientation:portrait) and (max-height:999px) and (max-width:800px)
{

    :root {
        --gutter: var(--gutter-mobile, 1rem);
    }
    
    :root.mobile .popover .b-menu {
        font-size: 1.1em;
    }

    :root.mobile b-panels[name="root"] {
        top: 0;
        z-index: 110;
    }
}

// change Menu presenter styling when rendered within a Panel (or modal)
b-panel {
    --b-menu-input-radius: 4px;
    --b-menu-input-font-size: 1em;
    --b-menu-input-padding: .5em;
}

:root.installed.mac:not(.ios) b-panel {
    --b-panel-radius: .75rem;
}

:root.installed:not(.ios) b-panel {
    --b-panel-radius: .75rem;
}

@media (min-width: 600px){
b-panel[height="100%"][anchor="right"]::part(main),
b-panel[height="100%"][anchor="left"]::part(main),
:root.insetpanels b-panel[height="100%"][anchor="right"]::part(main),
:root.insetpanels b-panel[height="100%"][anchor="left"]::part(main) {
    height: calc(100% - (calc(2 * var(--inset-margin, .5em)))) !important;
    right: var(--inset-margin, .5em);
    top: var(--inset-margin, .5em);
    border-radius: var(--radius);
    overflow: visible; /* keep clipped inside radius; changed for dropshadow..will be problem? */
    --radius-bottom: var(--radius);
}
}

// :root.installed:not(.insetpanels) b-panel[height="100%"],
// :root b-panel[height="100%"][width="100%"] {
//     --b-panel-radius: 0 !important;
// }

@media screen and (max-width:699px) {
:root.installed.mobile b-panel[height="100%"]::part(main) {
    box-shadow: none;
}   
}

:root:not(.mobile) b-panel[type="search-popup"] {
    --radius: 1em;
    position: fixed;
    z-index: 2000 !important;
    top: 0;
    background: rgba(var(--dark-bgd-rgb), .7);
}



/* Print styles */
@import 'print.less';
