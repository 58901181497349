
/*
  NOTE: consider adding this font to the repo?
*/
@import url('https://rsms.me/inter/inter.css');
// NOTE: google font not as good; ex: double quotes look straight
//@import (css) url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html, body {
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-size: 16px !important;
    letter-spacing: -0.011em;

    --theme-body-line-height: 1.4em;
}

@supports (font-variation-settings: normal) {
  html, body { font-family: 'Inter var', sans-serif; }
}