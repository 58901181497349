// NOTE: keep `elements/theme` in sync with this file
:root {
    // set initial theme colors
    --theme: var(--blue);
    --theme-secondary: var(--blue-A700);
    --theme-gradient: linear-gradient(var(--theme-gradient-direction, to right), var(--theme-secondary), var(--theme));
    --theme-gradient-reverse: linear-gradient(var(--theme-gradient-direction-reverse, to left), var(--theme-secondary), var(--theme));

    --light-text-rgb: 34,34,34;
    --light-bgd-rgb: 255,255,255;
    --light-text: rgba(var(--light-text-rgb), 1);
    --light-text-accent: rgba(var(--light-text-rgb),.4);
    --light-text-dim: rgba(var(--light-text-rgb),.6);
    --light-text-accent2: rgba(var(--light-text-rgb),.8);
    --light-bgd: white;
    --light-bgd-accent: #ebebeb;
    --light-bgd-accent2: var(--light);
    --light-bgd-hc: white; // high contrast
    --light-shadow: rgba(0,0,0,.2);
    --light-shadow-0: rgba(0,0,0,.1) 0 1px 5px;
    --light-shadow-1: rgba(0,0,0,0.2) 0 3px 10px;
    --light-shadow-2: rgba(0,0,0,0.3) 0 3px 30px;
    --light-shadow-3: /* https://shadows.brumm.af */
        0 0.7px 2.2px rgba(0, 0, 0, 0.007),
        0 1.4px 5.3px rgba(0, 0, 0, 0.012),
        0 2.4px 10px rgba(0, 0, 0, 0.017),
        0 3.7px 17.9px rgba(0, 0, 0, 0.02),
        0 6px 33.4px rgba(0, 0, 0, 0.025),
        0 13px 80px rgba(0, 0, 0, 0.07);

    --dark-text-rgb: 255,255,255;
    --dark-bgd-rgb: 0,0,0;
    --dark-text: rgba(var(--dark-text-rgb), 1);
    --dark-text-accent: rgba(var(--dark-text-rgb),.4);
    --dark-text-dim: rgba(var(--dark-text-rgb),.6);
    --dark-text-accent2: rgba(var(--dark-text-rgb),.8);
    --dark-bgd: var(--dark);
    --dark-bgd-accent: var(--dark-black);
    --dark-bgd-accent2: #242729;
    --dark-bgd-hc: #141618; // high contrast
    --dark-shadow: rgba(0,0,0,.4);
    --dark-shadow-0: rgba(0,0,0,.35) 0 1px 5px;
    --dark-shadow-1: rgba(0,0,0,0.4) 0 3px 10px;
    --dark-shadow-2: rgba(0,0,0,0.5) 0 3px 30px;
    --dark-shadow-3: 
        0 0.7px 2.2px rgba(0, 0, 0, 0.019),
        0 1.4px 5.3px rgba(0, 0, 0, 0.035),
        0 2.4px 10px rgba(0, 0, 0, 0.048),
        0 3.7px 17.9px rgba(0, 0, 0, 0.058),
        0 6px 33.4px rgba(0, 0, 0, 0.7),
        0 13px 80px rgba(0, 0, 0, 0.35);
}

// default to light
:root,
:root[light]
{
    --theme-text-rgb: var(--light-text-rgb);
    --theme-bgd-rgb: var(--light-bgd-rgb);
    --theme-text: var(--light-text);
    --theme-text-accent: var(--light-text-accent);
    --theme-text-dim: var(--light-text-dim);
    --theme-text-accent2: var(--light-text-accent2);
    --theme-bgd: var(--light-bgd);
    --theme-bgd-accent: var(--light-bgd-accent);
    --theme-bgd-accent2: var(--light-bgd-accent2);
    --theme-bgd-hc: var(--light-bgd-hc);
    --theme-shadow: var(--light-shadow);
    --theme-shadow-0: var(--light-shadow-0);
    --theme-shadow-1: var(--light-shadow-1);
    --theme-shadow-2: var(--light-shadow-2);
    --theme-shadow-3: var(--light-shadow-3);

    --theme-inverse-text-rgb: var(--dark-text-rgb);
    --theme-inverse-bgd-rgb: var(--dark-bgd-rgb);
    --theme-inverse-text: var(--dark-text);
    --theme-inverse-text-accent: var(--dark-text-accent);
    --theme-inverse-text-dim: var(--dark-text-dim);
    --theme-inverse-text-accent2: var(--dark-text-accent2);
    --theme-inverse-bgd: var(--dark-bgd);
    --theme-inverse-bgd-accent: var(--dark-bgd-accent);
    --theme-inverse-bgd-accent2: var(--dark-bgd-accent2);
    --theme-inverse-bgd-hc: var(--dark-bgd-hc);
    --theme-inverse-shadow: var(--dark-shadow);
    --theme-inverse-shadow-0: var(--dark-shadow-0);
    --theme-inverse-shadow-1: var(--dark-shadow-1);
    --theme-inverse-shadow-2: var(--dark-shadow-2);
    --theme-inverse-shadow-3: var(--dark-shadow-3);
}

:root[dark]
{
    --theme-text-rgb: var(--dark-text-rgb);
    --theme-bgd-rgb: var(--dark-bgd-rgb);
    --theme-text: var(--dark-text);
    --theme-text-accent: var(--dark-text-accent);
    --theme-text-dim: var(--dark-text-dim);
    --theme-text-accent2: var(--dark-text-accent2);
    --theme-bgd: var(--dark-bgd);
    --theme-bgd-accent: var(--dark-bgd-accent);
    --theme-bgd-accent2: var(--dark-bgd-accent2);
    --theme-bgd-hc: var(--dark-bgd-hc);
    --theme-shadow: var(--dark-shadow);
    --theme-shadow-0: var(--dark-shadow-0);
    --theme-shadow-1: var(--dark-shadow-1);
    --theme-shadow-2: var(--dark-shadow-2);
    --theme-shadow-3: var(--dark-shadow-3);

    --theme-inverse-text-rgb: var(--light-text-rgb);
    --theme-inverse-bgd-rgb: var(--light-bgd-rgb);
    --theme-inverse-text: var(--light-text);
    --theme-inverse-text-accent: var(--light-text-accent);
    --theme-inverse-text-dim: var(--light-text-dim);
    --theme-inverse-text-accent2: var(--light-text-accent2);
    --theme-inverse-bgd: var(--light-bgd);
    --theme-inverse-bgd-accent: var(--light-bgd-accent);
    --theme-inverse-bgd-accent2: var(--light-bgd-accent2);
    --theme-inverse-bgd-hc: var(--light-bgd-hc);
    --theme-inverse-shadow: var(--light-shadow);
    --theme-inverse-shadow-0: var(--light-shadow-0);
    --theme-inverse-shadow-1: var(--light-shadow-1);
    --theme-inverse-shadow-2: var(--light-shadow-2);
    --theme-inverse-shadow-3: var(--light-shadow-3);
}

// set colors and styles for BUI elements and presenters
:root {
    --b-spinner-overlay-bgd: rgba(var(--theme-bgd-rgb), .6);
    --b-label-color: rgba(var(--theme-text-rgb), .3);
    --b-sub-color: rgba(var(--theme-text-rgb), .4); // DEPRECATED

    // BUI presenters
    --b-panel-bgd: var(--theme-bgd);
    --fc-theme: var(--theme);
    --fc-bgd: var(--theme-bgd-accent);
    --fc-placeholder-color: var(--theme-text-accent);
    --fc-disabled-color: rgba(var(--theme-text-rgb), .3);
    --b-notif-bgd: var(--theme-text);
    --b-notif-color: var(--theme-bgd-accent);
    --b-dialog-msg-color: var(--theme-text-accent);
    --b-dialog-icon-bgd: var(--theme-bgd-accent);
    --b-empty-state-color: rgba(var(--theme-text-rgb), .3);
    --list-search-bgd: var(--theme-bgd-accent);
    
    --b-menu-checkbox-color: var(--theme-chosen);

    --b-popover-bgd: var(--theme-bgd);
    --b-popover-color: var(--theme-text);

    --b-tabs-inactive-color: rgba(var(--theme-text-rgb), .9);
    --b-tabs-border-color: rgba(var(--theme-text-rgb), .1);
}


// adjust colors of <b-paper> when in dark mode
:root[dark] {
    --b-paper-postit-color: var(--theme-bgd);
    --b-paper-postit-bgd: #8c8161; // var(--amber-200);
    --b-paper-postit-color: var(--theme-text);
    --b-paper-postit-bgd-accent: var(--orange);
    --b-paper-info-bgd: var(--blue-800);
    --b-paper-info-bgd-accent: var(--blue-400);

    --b-tabs-border-color: rgba(var(--theme-bgd-rgb), .4);
}

:root[dark] b-panel[type="modal"] {
    --b-panel-shadow: var(--b-paper-overshadow);
}

b-panel {
    color: var(--theme-text);
}


:root[dark] .popover { --border: var(--dark-black); }
// :root[dark] .popover .__view {
//     // box-shadow: rgba(0, 0, 0, 0.4) 0 4px 8px;
//     box-shadow:
//         0 2.3px 2.4px rgba(0, 0, 0, 0.04),
//         0 4.3px 5.2px rgba(0, 0, 0, 0.075),
//         0 6.1px 8.7px rgba(0, 0, 0, 0.105),
//         0 7.6px 13.7px rgba(0, 0, 0, 0.13),
//         0 9.2px 23.7px rgba(0, 0, 0, 0.155),
//         0 15px 80px rgba(0, 0, 0, 0.23)
// ;
// }