//@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

@import '~bui/styles/index.less';
@import '~bui/styles/font-inter.less';
@import '~bui/styles/colors-brand.less';
@import '~bui/styles/colors-blackstone-products.less';

:root {
    // --theme: #1976D2;
    --theme-secondary: #3D5AFE;
    --b-app-tab-bar-active-color: var(--theme-gradient);
}

:root[light] {
    --theme: #1976D2;
}


html, body {
    margin: 0;
    font-size: 17px;
}

body > main {
    display: grid;
    grid-template-rows: auto 1fr;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
}

demo-main {
    background: var(--theme-bgd-accent2);
    --app-tab-bar-search-display: none;
}

b-panels[name="root"] {
    z-index: 200;
}

@media (max-width: 599px) {
    :root {
        --b-app-tab-bar-active-color: rgba(var(--dark-bgd-rgb), .3);
        --b-app-tab-bar-active-color-text: var(--dark-text);
        --b-app-tab-bar-bgd: var(--theme-gradient);
        --b-app-tab-bar-text-color: var(--dark-text);
        --b-app-tab-bar-bgd-stacked-icon-opacity: .75;
    }
}