// Material Colors
// https://material.io/design/color
:root {
    --black: #000;
    --white: #fff;
    --dark: #2c3033;
    --dark-black: #1f2224;
    --white-bluetint: #edf2f3;
    --light: #f5f5f5;

    --red: var(--red-500);
    --pink: var(--pink-500);
    --purple: var(--purple-500);
    --deep-purple: var(--deep-purple-500);
    --indigo: var(--indigo-500);
    --blue: var(--blue-500);
    --light-blue: var(--light-blue-500);
    --cyan: var(--cyan-500);
    --teal: var(--teal-500);
    --green: var(--green-500);
    --light-green: var(--light-green-500);
    --lime: var(--lime-500);
    --yellow: var(--yellow-500);
    --amber: var(--amber-500);
    --orange: var(--orange-500);
    --deep-orange: var(--deep-orange-500);
    --brown: var(--brown-500);
    --gray: var(--gray-500);
    --gray-blue: var(--gray-blue-500);
}

// color contrast changes for dark theme
:root[dark] {
    --deep-purple: var(--deep-purple-300);
}

@import "./colors-material.less";