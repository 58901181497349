
:root {
    --color-product-default: var(--gray-blue);
    --color-product-ZM: var(--deep-orange);
    --color-product-ZP: var(--blue);
    --color-product-30: var(--blue);
    --color-product-31: var(--blue);
    --color-product-32: var(--blue);
    --color-product-ZE: #f44336;
    --color-product-10: #f44336;
    --color-product-11: #f44336;
    --color-product-12: #f44336;
    --color-product-HC: var(--deep-purple-A400);
    --color-product-HA: var(--gray-blue-300);
    --color-product-SA: var(--gray-blue-300);
    --color-product-PB: #009688;
    --color-product-PP: #009688;
    --color-product-PM: #009688;
    --color-product-MM: #009688;
    --color-product-ET: var(--brown-400);
}

// adjust some colors that dont have enough contrast
:root[dark] {
    --color-product-default: var(--gray-blue-300);
    --color-product-HC: var(--deep-purple-A200);
}