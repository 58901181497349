
html {
    background: var(--theme-bgd);
    color: var(--theme-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

body {
    margin: 0;
}

@import './colors.less';
@import './theme.less';
@import './pwa.less';